import React, { useContext } from 'react';
import pt from 'prop-types';
import Container from '../../../../../modules/container';
import CustomScrollable from '../../../../ui/customScrollable';
import TeaserItem from '../../../../ui/teaserItem';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getCopy } from '../../../../../helpers/copy';
import Headline from '../../../../ui/headline';
import * as styles from './styles.module.scss';

const Slider = ({ items, headline, unstyledFallback }) => {
  const microcopy = useContext(MicrocopyContext);
  return (
    <Container className={styles.slider}>
      <Headline as="h3" type="h1" text={getCopy(headline, microcopy)} />
      <CustomScrollable className={styles.scrollContainer}>
        {items &&
          items.map((item, index) => (
            <TeaserItem
              key={index}
              image={{ url: item.image, description: item.companyName }}
              title={item.companyName || item}
              info={item.firstClient}
              imageBadge={item.logo}
              link={item.slug ? { linkToPage: item.slug } : null}
              isInSlider
              unstyledFallback={unstyledFallback}
            />
          ))}
      </CustomScrollable>
    </Container>
  );
};

Slider.propTypes = {
  items: pt.array,
  headline: pt.string.isRequired,
  unstyledFallback: pt.bool,
};

Slider.defaultProps = {
  items: [],
  unstyledFallback: false,
};

export default Slider;
