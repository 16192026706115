import React from 'react';
import pt from 'prop-types';
import { format, getDate } from 'date-fns';
import Link, { linkProptypes } from '../../../../ui/link';
import Image, { imageProptypes } from '../../../../ui/image';
import Text from '../../../../ui/text';
import Icon from '../../../../ui/icon';
import Skeleton from '../../../../ui/skeleton';
import * as styles from './styles.module.scss';
import { combine } from '../../../../../helpers/styles';

const EventItem = ({
  startDate,
  endDate,
  image,
  category,
  title,
  location,
  country,
  link,
  isSkeleton,
}) => {
  if (isSkeleton) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.date}>
          <Skeleton />
        </div>
        <div className={styles.content}>
          {image && image.url && (
            <Image image={image} imgClassName={styles.image} />
          )}
          <Skeleton className={styles.image} />
          <div className={styles.details}>
            <Skeleton />
          </div>
        </div>
        <div className={styles.link}>
          <Skeleton className={styles.image} />
        </div>
      </div>
    );
  }

  return (
    <Link link={link} className={styles.wrapper} useGatsbyLink={false}>
      <div className={styles.date}>
        <div className={styles.eventDateContainer}>
          {startDate && (
            <div className={styles.innerDateContainer}>
              <Text as="span" type="infotext" className={styles.widthFull}>
                {format(new Date(startDate), 'EEEEEE')}
              </Text>
              <Text
                as="span"
                type="p1"
                variant="semibold"
                className={styles.widthFull}
              >
                {getDate(new Date(startDate))}{' '}
                {endDate
                  ? format(new Date(startDate), 'LLL')
                  : format(new Date(startDate), 'LLLL')}
              </Text>
              <Text as="span" type="infotext" className={styles.widthFull}>
                {format(new Date(startDate), 'yyyy')}
              </Text>
            </div>
          )}
          {endDate && startDate !== endDate && (
            <>
              <Text
                as="span"
                type="infotext"
                className={styles.middleHyphen}
              ></Text>
              <div
                className={combine(styles.innerDateContainer, styles.endDate)}
              >
                <Text as="span" type="infotext" className={styles.widthFull}>
                  {format(new Date(endDate), 'EEEEEE')}
                </Text>
                <Text
                  as="span"
                  type="p1"
                  variant="semibold"
                  className={styles.widthFull}
                >
                  {getDate(new Date(endDate))}{' '}
                  {format(new Date(endDate), 'LLL')}
                </Text>
                <Text as="span" type="infotext" className={styles.widthFull}>
                  {format(new Date(endDate), 'yyyy')}
                </Text>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.outerContainer}>
        <div className={styles.content}>
          {image && image.url && (
            <Image image={image} imgClassName={styles.image} />
          )}
          <div className={styles.details}>
            {category && (
              <Text as="span" type="infotext" variant="condensed">
                {category.toUpperCase()}
              </Text>
            )}
            {title && (
              <Text as="span" type="p1" variant="semibold" style="editorial">
                <u>{title}</u>
              </Text>
            )}
            <div>
              {location && (
                <Text as="span" type="infotext">
                  {location}
                </Text>
              )}
              {country && (
                <>
                  <Text as="span" type="infotext" className={styles.country}>
                    {country}
                  </Text>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.link}>
          <Icon name="arrow-right" size="2x" className={styles.arrow} />
        </div>
      </div>
    </Link>
  );
};

EventItem.propTypes = {
  link: pt.shape(linkProptypes),
  image: pt.shape(imageProptypes),
  startDate: pt.string,
  category: pt.string,
  title: pt.string,
  location: pt.string,
  isSkeleton: pt.bool,
};

EventItem.defaultProps = {
  link: null,
  image: null,
  startDate: '',
  category: '',
  title: '',
  location: '',
  isSkeleton: false,
};

export default EventItem;
