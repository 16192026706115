// extracted by mini-css-extract-plugin
export var borderBottom = "styles-module--borderBottom--cd88e";
export var button = "styles-module--button--c004b";
export var category = "styles-module--category--41e23";
export var header = "styles-module--header--190db";
export var icon = "styles-module--icon--75d2a";
export var logo = "styles-module--logo--c3dd5";
export var preview = "styles-module--preview--717f0";
export var ranking = "styles-module--ranking--1269b";
export var rankingItem = "styles-module--rankingItem--c9458";
export var row = "styles-module--row--cd172";
export var underline = "styles-module--underline--76cfe";
export var withoutBorder = "styles-module--withoutBorder--ef87a";