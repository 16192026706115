import React, { useContext } from 'react';
import pt from 'prop-types';
import Image from '../../../../ui/image';
import Text from '../../../../ui/text';
import Fact from '../../../../ui/fact';
import Container from '../../../../../modules/container';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import {
  getCopy,
  getFormattedEmployeeAmount,
} from '../../../../../helpers/copy';
import * as styles from './styles.module.scss';
import { combine } from '../../../../../helpers/styles';

const Overview = ({
  image,
  companyName,
  statement,
  headquarter,
  locations,
  hashtags,
  intro,
  founded,
  employees,
  className,
}) => {
  const microcopy = useContext(MicrocopyContext);
  return (
    <Container fullWidth className={combine(styles.overview, className)}>
      <Container className={styles.container}>
        {image ? (
          <Image
            image={{ url: image }}
            className={styles.imageWrapper}
            imgClassName={styles.image}
          />
        ) : (
          <div>
            {companyName && (
              <Text as="h1" type="h1" variant="semibold">
                {companyName}
              </Text>
            )}
            {intro && (
              <Text as="p" type="h3" className={styles.introWithoutImage}>
                {intro}
              </Text>
            )}
          </div>
        )}
        <div
          className={combine(styles.description, !image && styles.withoutImage)}
        >
          {image && companyName && (
            <Text as="h1" type="h1" variant="semibold">
              {companyName}
            </Text>
          )}
          {image && intro && (
            <Text as="p" type="h3">
              {intro}
            </Text>
          )}
          {hashtags && !!hashtags.length && (
            <div className={styles.sectors}>
              <Text as="h3" type="h3" variant="semibold">
                {getCopy('label.sectors', microcopy)}
              </Text>
              <ul className={styles.list}>
                {hashtags.map((hashtag, index) => (
                  <li key={index}>
                    <Text as="span" type="h3">
                      {hashtag}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className={styles.table}>
            {headquarter && (
              <Fact
                label={getCopy('table.headquarter', microcopy)}
                value={headquarter}
              />
            )}
            {locations && (
              <Fact
                label={getCopy('table.locations', microcopy)}
                value={locations}
              />
            )}
            {founded && (
              <Fact
                label={getCopy('table.founded', microcopy)}
                value={founded.toString()}
              />
            )}
            {employees && (
              <Fact
                label={getCopy('table.employees', microcopy)}
                value={getFormattedEmployeeAmount(employees)}
              />
            )}
          </div>
        </div>
        {statement && (
          <div className={styles.statement}>
            <Text as="h3" type="h3" variant="semibold" className={styles.title}>
              {getCopy('label.designStatement', microcopy)}
            </Text>
            <Text as="div" type="p1" className={styles.content}>
              {statement}
            </Text>
          </div>
        )}
      </Container>
    </Container>
  );
};

Overview.propTypes = {
  image: pt.string,
  companyName: pt.string,
  statement: pt.string,
  headquarter: pt.string,
  locations: pt.string,
  hashtags: pt.array,
  intro: pt.string,
  founded: pt.number,
  employees: pt.number,
  className: pt.string,
};

Overview.defaultProps = {
  image: '',
  companyName: '',
  statement: '',
  headquarter: '',
  locations: '',
  hashtags: [],
  intro: '',
  founded: 0,
  employees: 0,
};

export default Overview;
