// extracted by mini-css-extract-plugin
export var container = "styles-module--container--cd241";
export var content = "styles-module--content--c10df";
export var description = "styles-module--description--571b8";
export var image = "styles-module--image--1ffb5";
export var imageWrapper = "styles-module--imageWrapper--2f8c9";
export var introWithoutImage = "styles-module--introWithoutImage--d7e3e";
export var list = "styles-module--list--3a1c0";
export var overview = "styles-module--overview--f5b85";
export var sectors = "styles-module--sectors--84a5b";
export var statement = "styles-module--statement--24dbc";
export var table = "styles-module--table--7c6a0";
export var title = "styles-module--title--db5a5";
export var withoutImage = "styles-module--withoutImage--e95f4";