import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { getEventLink } from '../../../helpers/getEventLink';
import Container from '../../../modules/container';
import Headline, { headlinePropTypes } from '../../ui/headline';
import RichText, { richTextPropTypes } from '../../ui/richText';
import Link, { linkProptypes } from '../../ui/link';
import Text from '../../ui/text';
import Icon from '../../ui/icon';
import EventItem from './components/eventItem';
import * as styles from './styles.module.scss';

import { getAPIRoot } from '../../../helpers/fetch';

const CMSEventTeaser = ({
  customEvents,
  headline,
  text,
  link,
  specificIds,
  fromDate,
  toDate,
  order,
}) => {
  const [events, setEvents] = useState([]);
  const page = 0;
  const sort = order === 'Descending' ? 'desc' : 'asc';

  const fetchEvents = async () => {
    let apiRoot = await getAPIRoot();

    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        categories: [],
        years: [],
        regions: [],
        find: '',
        ...(fromDate && { fromDate }),
        ...(toDate && { toDate }),
      }),
    };

    if (specificIds?.length) {
      let promises = specificIds
        .split(',')
        .map((id) =>
          fetch(`${apiRoot}${process.env.GATSBY_API_EVENTS_DETAIL}/${id}`).then(
            (res) => res.json()
          )
        );
      setEvents(await Promise.all(promises));
    } else {
      await fetch(
        `${apiRoot}${process.env.GATSBY_API_EVENTS}/${page}/${sort}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          const sortedEvents = res.items.sort((a, b) => {
            return new Date(b.startDate) - new Date(a.startDate);
          });
          setEvents(sortedEvents);
        });
    }
  };

  useEffect(() => {
    if (customEvents && customEvents.length > 0) {
      return setEvents(customEvents);
    }
    return fetchEvents();
  }, []);

  return (
    <Container fullWidth className={styles.events}>
      <Container>
        {headline && (
          <Headline
            text={headline}
            level="h2"
            type="h1"
            className={styles.title}
          />
        )}

        {text && typeof text === 'string' && (
          <Text
            as="p"
            type="metanavi"
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {text && text.raw && (
          <RichText
            paragraphType="metanavi"
            content={text}
            className={styles.description}
          />
        )}

        <div className={styles.eventsTable}>
          {events && events.length > 0
            ? events
                .slice(0, 2)
                .map((item, index) => (
                  <EventItem
                    key={index}
                    image={{ url: item.image, description: item.title }}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    category={item.category}
                    title={item.title}
                    location={item.location}
                    country={item.country}
                    link={{ linkToPage: getEventLink(item.title, item.id) }}
                  />
                ))
            : Array.from(Array(2).keys()).map((_, index) => (
                <EventItem key={index} isSkeleton />
              ))}
        </div>
        {link && (
          <div className={styles.button}>
            <Link link={link} asButton className={styles.link}>
              <Text as="span" type="button">
                {link.text}
              </Text>
              <Icon
                name="calendar-alt"
                width={25}
                height={25}
                className={styles.icon}
              />
            </Link>
          </div>
        )}
      </Container>
    </Container>
  );
};

CMSEventTeaser.propTypes = {
  headline: pt.shape(headlinePropTypes),
  text: pt.oneOfType([pt.shape(richTextPropTypes)]),
  link: pt.shape(linkProptypes),
  customEvents: pt.array,
  specificIds: pt.arrayOf(pt.number),
  fromDate: pt.object,
  toDate: pt.object,
  order: pt.oneOf(['Ascending', 'Descending']),
};

CMSEventTeaser.defaultProps = {
  headline: '',
  text: '',
  link: '',
  customEvents: [],
  specificIds: [],
  order: 'Descending',
};

export default CMSEventTeaser;
