// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--29c8c";
export var content = "styles-module--content--0d06e";
export var country = "styles-module--country--21f40";
export var date = "styles-module--date--9063d";
export var details = "styles-module--details--6a861";
export var endDate = "styles-module--endDate--26146";
export var eventDateContainer = "styles-module--eventDateContainer--8389b";
export var image = "styles-module--image--88e16";
export var innerDateContainer = "styles-module--innerDateContainer--c396f";
export var link = "styles-module--link--fc079";
export var middleHyphen = "styles-module--middleHyphen--b4143";
export var outerContainer = "styles-module--outerContainer--fcf18";
export var widthFull = "styles-module--widthFull--892e3";
export var wrapper = "styles-module--wrapper--81fe4";