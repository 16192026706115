import React, { useContext } from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import Link from '../../../../ui/link';
import Icon from '../../../../ui/icon';
import Image from '../../../../ui/image';
import Container from '../../../../../modules/container';
import * as styles from './styles.module.scss';
import SocialShare from '../../../../ui/socialShare';
import Logo from '../../../../../images/logo.svg';
import { format } from 'date-fns';
import { combine } from '../../../../../helpers/styles';

const GOLDEN_ANNIVERSARY = [50, 60, 65];
const SILVER_ANNIVERSARY = 25;
const getTypeStyles = (years) => {
  if (GOLDEN_ANNIVERSARY.includes(years)) {
    return styles.golden;
  }
  if (SILVER_ANNIVERSARY === years) {
    return styles.silver;
  }
  return false;
};

const Header = ({
  logo,
  anniversaryText,
  anniversaryDate,
  profileType,
  sumOfProjects,
  isCompanyDetail,
  shareURL,
  socialLinks,
  hasContact,
  companyWebsite,
}) => {
  const anniversaryYears =
    anniversaryDate &&
    anniversaryText &&
    parseInt(format(new Date(anniversaryDate), 'yyyy')) -
      parseInt(anniversaryText.slice(-4));

  return (
    <Container className={combine(styles.container)} fullWidth>
      <Container className={styles.header}>
        <div className={styles.images}>
          {logo && (
            <div className={styles.logo}>
              <Image image={{ url: logo }} />
            </div>
          )}
          {anniversaryYears && (
            <div
              className={combine(
                styles.logo,
                styles.anniversary,
                getTypeStyles(anniversaryYears)
              )}
            >
              <Text as="span" type="h2" className={styles.title}>
                {anniversaryYears} YEARS
              </Text>
              <Text as="span" type="infotext">
                {anniversaryText}
              </Text>
              <div className={styles.bottom}>
                <Logo width={50} height={50} />
                <Text as="span">
                  {format(new Date(anniversaryDate), 'MMMM yyyy')}
                </Text>
              </div>
            </div>
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.companyName}>
            <Text as="p" type="button" className={styles.name}>
              {profileType}
            </Text>
            <div className={styles.type}>
              <Text as="span" type="h2" variant="semibold">
                {sumOfProjects}
              </Text>
              <Text as="p" type="button" className={styles.ifAwards}>
                iF AWARDS
              </Text>
            </div>
          </div>
          <div className={styles.contact}>
            <SocialShare
              shareURL={shareURL}
              isCompanyDetail={isCompanyDetail}
              hasContact={hasContact}
              socialLinks={socialLinks}
              companyWebsite={companyWebsite}
            />
          </div>
        </div>
      </Container>
    </Container>
  );
};

Header.propTypes = {
  logo: pt.string,
  anniversaryText: pt.string,
  anniversaryDate: pt.string,
  profileType: pt.string,
  sumOfProjects: pt.number,
  isCompanyDetail: pt.bool,
  shareURL: pt.string,
  hasContact: pt.bool,
  socialLinks: pt.array,
  companyWebsite: pt.string,
  className: pt.string,
};

Header.defaultProps = {
  logo: '',
  anniversaryText: '',
  anniversaryDate: '',
  profileType: '',
  sumOfProjects: 0,
  isCompanyDetail: false,
  shareURL: '',
  hasContact: false,
  socialLinks: [],
  companyWebsite: '',
  className: '',
};

export default Header;
