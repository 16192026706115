import React, { useContext } from 'react';
import pt, { bool, string } from 'prop-types';
import Icon from '../../../components/ui/icon';
import Fact from '../../../components/ui/fact';
import Container from '../../../modules/container';
import Link, { linkProptypes } from '../../../components/ui/link';
import Headline, { headlinePropTypes } from '../../ui/headline';
import RichText, { richTextPropTypes } from '../../ui/richText';
import { MicrocopyContext } from '../../../context/microcopy.context';
import { getCopy, getFormattedEmployeeAmount } from '../../../helpers/copy';
import Text from '../../ui/text';
import * as styles from './styles.module.scss';

const CMSContact = ({ headline, items, socialLinks }) => {
  const microcopy = useContext(MicrocopyContext);

  if (!items?.length && !socialLinks?.length) {
    return null;
  }

  return (
    <Container fullWidth className={styles.container} id={'contact'}>
      <Container className={styles.membership}>
        {headline && (
          <Headline text={headline} level="h3" type="h1" variant="light" />
        )}
        <div className={styles.iconGroup}>
          {socialLinks &&
            socialLinks.map((item, index) => (
              <Link link={item} key={index} className={styles.socialLink}>
                <Icon
                  name={item.icon}
                  width={70}
                  height={70}
                  type="fab"
                  className={styles.icon}
                />
              </Link>
            ))}
        </div>
        <div className={styles.info}>
          {items &&
            items.map((item, index) =>
              item.displayAsTable ? (
                <div key={index}>
                  {item.headquarter && (
                    <Fact
                      label={getCopy('table.headquarter', microcopy)}
                      value={item.headquarter}
                      labelClass={styles.factLabel}
                    />
                  )}
                  {item.locations && (
                    <Fact
                      label={getCopy('table.locations', microcopy)}
                      value={item.locations}
                      labelClass={styles.factLabel}
                    />
                  )}
                  {item.founded && (
                    <Fact
                      label={getCopy('table.founded', microcopy)}
                      value={item.founded}
                      labelClass={styles.factLabel}
                    />
                  )}
                  {item.employees && (
                    <Fact
                      label={getCopy('table.employees', microcopy)}
                      value={getFormattedEmployeeAmount(item.employees)}
                      labelClass={styles.factLabel}
                    />
                  )}
                </div>
              ) : (
                <div className={styles.details} key={index}>
                  {item.icon && (
                    <Icon
                      name={item.icon}
                      width={50}
                      height={50}
                      className={styles.icon}
                    />
                  )}
                  {item.contactInformation && item.contactInformation.raw && (
                    <RichText
                      content={item.contactInformation}
                      paragraphAs="p"
                      paragraphType="p1"
                      className={styles.address}
                    />
                  )}
                  {item.contactInformation &&
                    typeof item.contactInformation === 'string' && (
                      <Text
                        as="p"
                        type="p1"
                        className={styles.address}
                        dangerouslySetInnerHTML={{
                          __html: item.contactInformation,
                        }}
                      />
                    )}
                </div>
              )
            )}
        </div>
      </Container>
    </Container>
  );
};

CMSContact.propTypes = {
  headline: pt.shape(headlinePropTypes),
  socialLinks: pt.arrayOf(pt.shape(linkProptypes)),
  items: pt.arrayOf(
    pt.shape({
      headquarter: string,
      locations: string,
      founded: string,
      employees: string,
      displayAsTable: bool,
      icon: string,
      contactInformation: pt.oneOfType([
        pt.shape(richTextPropTypes),
        pt.string,
      ]),
    })
  ),
};

CMSContact.defaultProps = {
  headline: '',
  socialLinks: [],
  items: [],
};

export default CMSContact;
