// extracted by mini-css-extract-plugin
export var anniversary = "styles-module--anniversary--8b0e6";
export var bottom = "styles-module--bottom--ed062";
export var companyName = "styles-module--companyName--d0271";
export var contact = "styles-module--contact--0e66c";
export var container = "styles-module--container--96dcc";
export var golden = "styles-module--golden--574b0";
export var header = "styles-module--header--2facc";
export var icon = "styles-module--icon--ee79c";
export var ifAwards = "styles-module--ifAwards--d9c87";
export var images = "styles-module--images--cb372";
export var info = "styles-module--info--2fa5b";
export var logo = "styles-module--logo--ecd95";
export var name = "styles-module--name--db247";
export var preview = "styles-module--preview--b4a9e";
export var silver = "styles-module--silver--54307";
export var title = "styles-module--title--7e62b";
export var type = "styles-module--type--7f15f";