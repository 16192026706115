import React, { useContext } from 'react';
import pt from 'prop-types';
import Container from '../../../../../modules/container';
import CustomScrollable from '../../../../ui/customScrollable';
import FirstItem from '../../../../cms-modules/dynamicTeaser/components/firstItem';
import TeaserItem from '../../../../ui/teaserItem';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getCopy } from '../../../../../helpers/copy';
import * as styles from './styles.module.scss';

const Portfolio = ({ items }) => {
  const microcopy = useContext(MicrocopyContext);
  return (
    <Container className={styles.portfolio}>
      <CustomScrollable className={styles.scrollContainer}>
        <FirstItem headline={getCopy('labels.companyPortfolio', microcopy)} />
        {items &&
          items.map((item, index) => (
            <TeaserItem
              key={index}
              image={{ url: item.image1FileUrl }}
              title={item.name}
              link={{
                linkToPage: item.slug,
              }}
              isInSlider
            />
          ))}
      </CustomScrollable>
    </Container>
  );
};

Portfolio.propTypes = {
  items: pt.array,
};

Portfolio.defaultProps = {
  items: [],
};

export default Portfolio;
