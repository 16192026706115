import React, { useContext } from 'react';
import pt from 'prop-types';
import ImageWithText from './components/imageWithText';
import Container from '../../../../../modules/container';
import * as styles from './styles.module.scss';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getCopy } from '../../../../../helpers/copy';
import Headline from '../../../../ui/headline';
import VideoWithText from './components/videoWithText';

const AboutUs = ({ items }) => {
  const microcopy = useContext(MicrocopyContext);
  return (
    <Container className={styles.aboutUs}>
      <Headline as="h3" type="h1" text={getCopy('label.aboutUs', microcopy)} />
      {items &&
        items.map((item, index) =>
          item.videoHoster && item.videoId ? (
            <VideoWithText
              index={index}
              key={index}
              headline={item.title || item.description}
              videoHoster={item.videoHoster}
              videoId={item.videoId}
              text={(!!item.title && item.description) || ''}
              poster={item.imageFileUrl}
              link={{ externalLink: item?.externalUrl }}
            />
          ) : (
            <ImageWithText
              index={index}
              key={index}
              headline={item.title || item.description}
              image={{ url: item.imageFileUrl }}
              text={(!!item.title && item.description) || ''}
              link={{ externalLink: item?.externalUrl }}
            />
          )
        )}
    </Container>
  );
};

AboutUs.propTypes = {
  items: pt.array,
};

AboutUs.defaultProps = {
  items: [],
};

export default AboutUs;
