import React from 'react';
import pt from 'prop-types';
import Image, { imageProptypes } from '../../../../../../ui/image';
import Text from '../../../../../../ui/text';
import Link from '../../../../../../ui/link';
import Icon from '../../../../../../ui/icon';
import { combine } from '../../../../../../../helpers/styles';
import * as styles from './styles.module.scss';

const ImageWithText = ({ image, headline, text, link, index }) => (
  <div className={styles.itemWrapper}>
    {image && (
      <Image
        image={image}
        className={combine(index % 2 === 1 && styles.right)}
      />
    )}
    <div
      className={combine(styles.textContent, index % 2 === 1 && styles.left)}
    >
      {headline && (
        <Text as="h3" type="h2" variant="semibold" className={styles.hl}>
          {headline}
        </Text>
      )}
      {text && (
        <Text as="span" type="p1" className={styles.text}>
          {text}
        </Text>
      )}
      {link && (
        <Link link={link}>
          <Icon name="arrow-right" size="2x" className={styles.icon} />
        </Link>
      )}
    </div>
  </div>
);

ImageWithText.propTypes = {
  image: pt.shape(imageProptypes),
  headline: pt.string,
  text: pt.string,
  link: pt.object,
  index: pt.number,
};
ImageWithText.defaultProps = {
  index: 0,
  headline: '',
  text: '',
  link: null,
};

export default ImageWithText;
