import React, { useContext } from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import Link from '../../../../ui/link';
import TeaserItem from '../../../../ui/teaserItem';
import Container from '../../../../../modules/container';
import { getIfDesignAwardBadge } from '../../../../../helpers/badges';
import * as styles from './styles.module.scss';
import Headline from '../../../../ui/headline';
import { getCopy } from '../../../../../helpers/copy';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getWinnerOverviewPage } from '../../../../../helpers/getWinnerOverviewPage';

const Awarded = ({
  projectItems,
  amountOfProjects,
  companyName,
  companyId,
}) => {
  const microcopy = useContext(MicrocopyContext);

  return (
    <Container className={styles.awarded}>
      <div className={styles.header}>
        <Headline
          as="h3"
          type="h1"
          text={getCopy('label.awardedEntries', microcopy)}
          className={styles.withoutMargin}
        />
        <Text
          as="span"
          type="metanavi"
          variant="semibold"
          className={styles.projects}
        >
          {amountOfProjects} {getCopy('results.projects', microcopy)}
        </Text>
      </div>
      <div className={styles.entries}>
        {projectItems &&
          projectItems.slice(0, 9).map((item, index) => (
            <TeaserItem
              key={index}
              image={{
                url: item.primaryMediaThumbnail,
                description: item.name,
              }}
              title={item.name}
              subline={item.designation}
              info={item.firstClient}
              link={{
                linkToPage: item.slug,
              }}
              awardBadge={getIfDesignAwardBadge(item)}
            />
          ))}
      </div>
      {amountOfProjects > 9 && (
        <div className={styles.button}>
          <Link
            link={{
              linkToPage: `${getWinnerOverviewPage()}?profile=${encodeURIComponent(
                companyName
              )}|id=${companyId}`,
            }}
            asButton
            btnType="primary"
          >
            {getCopy('btn.showAll', microcopy)}
          </Link>
        </div>
      )}
    </Container>
  );
};

Awarded.propTypes = {
  projectItems: pt.array,
  amountOfProjects: pt.number,
  companyName: pt.string,
  companyId: pt.number.isRequired,
};

Awarded.defaultProps = {
  projectItems: [],
  amountOfProjects: 0,
  companyName: '',
};

export default Awarded;
