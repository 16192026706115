import React from 'react';
import pt from 'prop-types';
import Video, { videoPropTypes } from '../../../../../../ui/video';
import Text from '../../../../../../ui/text';
import Link from '../../../../../../ui/link';
import Icon from '../../../../../../ui/icon';
import { combine } from '../../../../../../../helpers/styles';
import * as styles from './styles.module.scss';

const VideoWithText = ({
  videoHoster,
  videoId,
  headline,
  text,
  link,
  index,
  poster,
}) => {
  const videoHosters = {
    1: 'Youtube',
    2: 'Vimeo',
  };
  const mapAndGetVideoUrl = (videoHoster, videoId) => {
    if (!videoHoster || !videoId) return null;
    const hoster = videoHosters[videoHoster];
    if (hoster === 'Youtube') {
      const youtubeVideo = {
        youtubeUrl: `https://www.youtube.com/watch?v=${videoId}`,
      };
      return youtubeVideo;
    } else if (hoster === 'Vimeo') {
      const vimeoVideo = {
        vimeoUrl: `https://player.vimeo.com/video/${videoId}`,
      };
      return vimeoVideo;
    }
  };
  return (
    <div className={styles.itemWrapper}>
      {videoHoster && videoId && (
        <Video
          video={mapAndGetVideoUrl(videoHoster, videoId)}
          className={combine(index % 2 === 1 && styles.right)}
          poster={{ image: { url: poster } }}
          playBtnClassname={combine(
            styles.playBtn,
            index % 2 === 1 && styles.right
          )}
        />
      )}
      <div
        className={combine(styles.textContent, index % 2 === 1 && styles.left)}
      >
        {headline && (
          <Text as="h3" type="h2" variant="semibold" className={styles.hl}>
            {headline}
          </Text>
        )}
        {text && (
          <Text as="span" type="p1" className={styles.text}>
            {text}
          </Text>
        )}
        {link && (
          <Link link={link}>
            <Icon name="arrow-right" size="2x" className={styles.icon} />
          </Link>
        )}
      </div>
    </div>
  );
};

VideoWithText.propTypes = {
  video: pt.shape(videoPropTypes),
  headline: pt.string,
  text: pt.string,
  link: pt.object,
  index: pt.number,
  poster: pt.string,
};
VideoWithText.defaultProps = {
  index: 0,
  headline: '',
  text: '',
  link: null,
  poster: '',
};

export default VideoWithText;
