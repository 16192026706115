import React, { useContext, useState } from 'react';
import pt from 'prop-types';
import Link from '../../../../ui/link';
import Text from '../../../../ui/text';
import Container from '../../../../../modules/container';
import AwardBadge from '../../../../ui/awardBadge';
import * as styles from './styles.module.scss';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getCopy } from '../../../../../helpers/copy';
import { combine } from '../../../../../helpers/styles';
import { getWDIFilterLink } from '../../../../../helpers/getWDIFilterLink';
import Button from '../../../../ui/button';
import { DEFAULT_IF_RANKING_PROFILE_TYPE } from '../../../../../utils/constants';

const Ranking = ({ rankings }) => {
  const [showingMore, setShowingMore] = useState(false);
  const microcopy = useContext(MicrocopyContext);
  const yearMin = rankings.sort(
    (a, b) => parseInt(a.labelAwardMinYear) - parseInt(b.labelAwardMinYear)
  )[0].labelAwardMinYear;
  const yearMax = rankings.sort(
    (a, b) => parseInt(b.labelAwardMaxYear) - parseInt(a.labelAwardMaxYear)
  )[0].labelAwardMaxYear;

  const getQueryParam = (entry) => {
    switch (entry.category) {
      case 'BY_CONTINENT':
        return `continents=${entry.filterName}`;
      case 'BY_COUNTRY':
        return `countries=${entry.filterName}`;
      case 'BY_KEYWORD':
        return `keywords=${entry.filterName}`;
      case 'BY_PROFILE_TYPE':
        return `profileTypes=${entry.filterName}`;
    }
  };

  return (
    <Container fullWidth className={combine(styles.ranking)}>
      <Container>
        <div className={styles.header}>
          <Text as="h3" type="h1" style="bigContent" className={styles.title}>
            {getCopy('label.ifRanking', microcopy)}
            {` ${yearMin} - ${yearMax}`}
          </Text>
          <div className={styles.button}>
            <Link
              link={{
                linkToPage: getWDIFilterLink(
                  `?page=1&profileTypes=${DEFAULT_IF_RANKING_PROFILE_TYPE}`
                ),
              }}
              asButton
              btnType="tertiary"
              btnTransparent
              className={styles.button}
            >
              {getCopy('label.allIfTopLabels', microcopy)}
            </Link>
          </div>
        </div>
        <div
          className={combine(
            styles.row,
            rankings.length > 2 && styles.withoutBorder
          )}
        >
          <div className={combine(styles.logo)}>
            <AwardBadge name="ifTopLabel" className={styles.icon} />
          </div>
          {rankings.slice(0, showingMore ? undefined : 5).map((item, index) => (
            <div
              className={combine(
                styles.rankingItem,
                index <= 1 && styles.borderBottom
              )}
            >
              <Text
                as="span"
                type="h2"
                variant="semibold"
                className={styles.category}
              >{`${item.rankLabel?.replace(
                '_',
                ' '
              )} ${item.filterLabel?.toUpperCase()}`}</Text>
              <Text
                as="span"
                type="h2"
              >{`${item.labelAwardMinYear} - ${item.labelAwardMaxYear}`}</Text>
              <Text as="span" type="p1">{`${item.rankLabel?.replace(
                '_',
                ' '
              )} ${getCopy('label.ranking.of', microcopy)} ${
                item.categoryFilterTotal
              }`}</Text>
            </div>
          ))}
        </div>
        {!showingMore && rankings?.length > 5 && (
          <div className={styles.button}>
            <Button
              onClick={() => setShowingMore(true)}
              text={getCopy('btn.showAll', microcopy)}
            />
          </div>
        )}
      </Container>
    </Container>
  );
};

Ranking.propTypes = {
  rankings: pt.array,
};

Ranking.defaultProps = {
  ranking: [],
};

export default Ranking;
